<template>
    <div class="tw-absolute tw-hidden">
        <h1>Toaster</h1>
    </div>
</template>

<script>
export default {
    name: "ToasterComponent",
};
</script>

<style scoped>
@import "../../app.scss";
</style>
