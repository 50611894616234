<template>
    <Toaster />
    <router-view />
</template>

<script>
import Toaster from "@libs/toaster/Toaster.vue";

export default {
    components: {
        Toaster,
    },
    setup() {},
};
</script>

<style lang="scss">
@import "../../app.scss";
</style>
