const routes = [
    {
        path: "",
        name: "adminRedirect",
        redirect: () => {
            // TODO: if logged in redirect to admin and also check beforEnter in admin
            return "/admin/login";
        },
    },
    {
        path: "login",
        name: "Login",
        component: () => import("@libs/admin/login/Login.vue"),
    },
];

export default routes;
